import React, { PureComponent, useState, useCallback, useEffect } from "react";
// import CountDownBlock from "./CountDownBlock";

import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">策展人/方彥翔</h2>
          <h1 className="main_title">一個論述的提案 (上)</h1>
          {/* <h6 className="speaker">策展人/方彥翔</h6> */}
        </div>

        <div className="page_content project_b">

          <section className="row_area_s project_b_a">
            <div className="column_5">
              <div className="context">
                {/* <h3>一個論述的提案</h3> */}
                <h4>Single-Person Tribe</h4>
                <p>當我們開始設想一種更為開放共融的社宅公共藝術時，馬上會想起「新類型公共藝術」（new genre public art）、「參與式藝術」（participatory art）、「社會參與實踐」（social engaged practice）等不同型態之藝術方案在藝術典範上的轉移；這些不同名詞創發之時空背景、美學態度與所側重的社會性面向有所不同，但是皆是以公共利益出發與社群基礎為導向，對公共性的定義與內涵進行探問與推進探問——從建築空間、設施建置、公共財、參與賦權、民主機制到身份認同等多層面的問題。</p>
                <p>在實踐上，「公共性」於當代生活的現實中意味著什麼？如同一種在民主機制之不穩定性旁側的產物，或者更像是由各種規範形成的資本市場空間？現實生活中各種碎片化的行為與選擇對於公共及分享的如何有更緊密的連結與促進？藝術方案有無可能成為公域的塑造者？美學作為方案於其中有沒有作用的空間？</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_b">
            <div className="column_6">
              <div className="context">
                <p>從「公共空間的藝術」到「關於公共性的藝術」再到公共的連結者，許多藝術實踐方案都嘗試進入公共性探討的不同縱深。由於「公眾」涉及不同的年齡、性別、族群、經濟能力、社會階級的混成，這類型的藝術活動被賦予一個全面性關照的更高裡想，公共藝術以往在於透過崇高性的美學表現，喚起一種公共或超越性的凝聚感。而在當代的流態社會中，從個體生活到公共生活（或者相反路徑）之間有著複雜的過程、通道與介面。當我們以「擴張中的場域」，來敲開場域的各種界線、注入能量與活性時，也意味著今日我們正面對著空間權力流動性的巨大改變；從銀幕到房門、從社區再到城市之間的距離、尺度與關係一直在重新組裝。</p>
                <p>因此今日，在社區公共藝術的範疇中討論，自然是某種對當前公共場域、生存新處境的重新勾勒，尋找新的商議方法與編造新的技術，幫助我們勾勒生活群體的形貌與生活邊界。所著重的會是差異如何被發掘、談論與展開。而本文則試圖透過幾個案例與想像，再訪幾個新的社區性藝術（或公共藝術方案）被密切討論的特質面向，嘗試從差異化的角度進一步推衍更多的可能性。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_c row_break">
            <div className="column_6 onset_left_1">
              <div className="context">
                <h4>共同生活與臨時社群</h4>
                <p>在日本藝術家田中功起（Koki Tanaka）名為《如何共同生活》（How to Live Together，2017）這個於《2017敏斯特雕塑計畫》（Skulptur Projekte Münster 2017）進行、命名引用自羅蘭．巴特同名著作的公共藝術項目中，田中功起邀集了八位來自敏斯特不同世代、背景的自願參與者，一同進行了為期九天的工作坊，期間藝術家安排參與者進行對話、在昔日避難所裡運動、烹煮戰時食譜共食聚餐、拍攝相互訪談的影片等共同活動。這裡借用並對話於羅蘭．巴特在《如何共同生活——某些日常空間的故事性模擬》（1976-1977法蘭西學院課程和研究班講義）所討論關於「個人節奏」（Idiorrhythmie）與集體生活的關係——在阿索斯山等東方修道院之修行生活模式，一種生活在一起的生產形式，其中一個人認識並尊重另一人的個人節奏。透過這些案例與相關文本，巴特分析探討了獨自生活與共同生活的幾重幻覺、實踐性、語言學及精神構造與意義。</p>
                <p>田中的計劃所進行的是形成一種無目的臨時性聚集。對話交流本身即是目的，一起完成一件事情形成某種集體經驗，再將此經驗分享至更廣大的群體。透過田中的項目，我們碰觸到幾個當代共同生活處境的關鍵課題：</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_d">
            <div className="column_6 onset_left_1">
              <div className="context">
                <p>首先，是關於面對共同體（或社群）的想像。共同生活是什麼樣的想像？共同體之想像是以甚麼途徑形成？在敏斯特的計畫之前，田中功起在一系列「嘗試」的作品，邀請多人來共同完成剪髮、陶藝、寫詩、鋼琴演奏等行為或創作；不過在這個類似「零度」社群的召集行動中，與其說這些作為是一種「協作」，不如說是創造一種「無共識」的集合。</p>
                <p>《如何共同生活》中這些來自不同族裔的自願者共處交談對話中，很自然地透露了當時歐洲區域迫切的難民危機與認同問題——而德國正是歐盟中難民政策最為寬鬆的國家，在 2014-2017 年期間德國接受了約 80 萬難民之庇護申請，也面對了各種從體制到生活、文化上的挑戰——共同體的想像與形成涉及了疆界的劃分、認同與生活共識的形成。我們可以說《如何共同生活》是試圖透過臨時社群以討論一個新共同體想像的基點。</p>
                <p>再者，臨時社群的背後存在著特殊狀態（或者緊急狀態）；特殊狀態（或緊急狀態）與臨時社群之間的關係，亦是田中晚近創作的關鍵核心：從福島核災後即關注著手在重新理解日常溝通以及抵抗社會孤立化，透過不同的行為與工作坊項目，試圖去召喚一種平日不存在或潛藏於日常中的集體動能。</p>
                <p>我們該如何去看待當代社會裡常態與非常態的關係？在羅蘭．巴特的文本與分析中，「共同生活」並非一種常態的生活狀態，它是落實在餐食、居住空間、身體距離、配對、圈地、自給自足、獨居隱修與聚集修道……等各層面的規範與實行方法上。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_d">
            <div className="column_5 onset_left_1">
              <div className="context">
                <p>田中回應臨時性狀態/劇變的方法是呼喚臨時社群，臨時社群往往是相應於突發事件與臨時性狀態而生，創造出一種共同的關係，往往必須是在非常態下而形成達成，換言之，是否我們必須透過不斷的非常態化，以形成所謂的「暫存社群」，一種可以暫時共有共處的處境。而想像在今日的社會經濟運作體系中，臨時社群的創生是否與在什麼時刻可行。</p>
                <p>日本藝術家加藤翼（Kato Tsubasa）一系列〈拉升〉（Pull And Raise）項目——包含廣為人知的《311光屋計畫》（The Lighthouses - 11.3 Project，2011）是藝術家參與福島災後支援工作時所進行的藝術行動，將拆遷的廢棄木頭重組為福島燈塔地標，並召集當地居民一起用繩索將木塔拉升豎立——同樣是探討關於臨時社群的喚起，其短暫性與脆弱性，一方面如同田中功起作品所顯現是微型的社會關係縮影，另一方面亦是世界現存狀態下有限資源與精神意志之間張力關係的映射。</p>
                <p>最後，雖然無論田中功起或加藤翼的作品在討論臨時群體，不過都可以看到他們所討論的「共同體」並不單單指著日常生活面對面相處的當下，都指向涵蓋與籠罩著集體的記憶與遺忘——在田中功起項目中即是他們進行工作坊所在的場址，那是一座過去從冷戰時期直到2015作為大規模毀滅性武器避難所的停車場；它就如同一個不被提及、或許已被淡忘卻仍然迴盪的幽靈。</p>
                <p><i>註1：這份論述書寫在《我們也可以這樣生活》中以一種概念假想的展覽提案的方式呈現，所列舉之藝術家並非邀請展出之藝術家，並未以實體呈現。</i></p>
                <h6>下集待續...</h6>
              </div>
            </div>
          </section>

        </div>

        <div className="page_footer">
          <Link to="/project_b_article_2" className="next_page">
            <div className="next_title">一個論述的提案 (下)</div>
            <div className="next_owner">策展人/方彥翔</div>
          </Link>
        </div>

      </div>


      // <CountDownBlock blockType="countDown" targetDate={new Date(2020, 3, 27, 22)}>
      //   <h4>《單人部落》</h4>
      //   <h5>一人成為聚落如何可能</h5>
      //   <h6>方彥翔/高雄市立美術館 助理策展人</h6>
      // </CountDownBlock>
    );
  }
}


export default Page
